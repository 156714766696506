import { ITypedReduxAction, TThunkAction } from '../../types/redux';
import { actionGenerator } from '../../utils/actionGenerator';
import { ResponseError } from '../../errors';
import { fetchMortgageSerpBannerContent } from '../../../repositoriesLegacy/mortgage/v1/mortgageSerpBanner/apiHelpers';

export enum EMortgageSerpBannerActionTypes {
  SetContent = 'MORTGAGE_SERP_BANNER/SET_CONTENT',
}

export type TMortgageSerpBannerSetContent = ITypedReduxAction<
  EMortgageSerpBannerActionTypes.SetContent,
  IMortgageSerpBannerSetContent
>;

export interface IMortgageSerpBannerSetContent {
  position: number;
  content: string;
  mortgageSerpBannerJSUrl: string;
}

export const mortgageSerpBannerSetContentAction = actionGenerator<
  EMortgageSerpBannerActionTypes.SetContent,
  IMortgageSerpBannerSetContent
>(EMortgageSerpBannerActionTypes.SetContent);

export const mortgageSerpBannerSetContent =
  (position: number): TThunkAction<Promise<void>> =>
  async (dispatch, getState, { logger, httpApi }) => {
    try {
      const {
        mortgageSerpBanner: { content },
      } = getState();
      if (content && !!content[position]) {
        return;
      }
      const { statusCode, response } = await fetchMortgageSerpBannerContent(httpApi, position);
      if (statusCode !== 200) {
        throw new ResponseError({
          domain: 'MortgageSerpBanner#fetchContent',
          message: response,
        });
      }
      const mortgageSerpBannerJSUrl = '//static.cdn-cian.ru/finance/serp-service-place/widget.js';
      const action = mortgageSerpBannerSetContentAction({
        content: response,
        position,
        mortgageSerpBannerJSUrl,
      });
      dispatch(action);
    } catch (error) {
      logger.error(error);
    }
  };
